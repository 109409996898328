import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"

import "./extesibleBannersCommon.css"
import timeConverter from "../utils/timeConverter"

export default function Trainings({ data }) {
  const trainings = data.allFile.nodes
  const bannerHeader = data.bannerHeader.childImageSharp.fluid
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  return (
    <Layout>
      <div className='background-image'>
        <Image
          fluid={bannerHeader}
          className='background-image-medium banner-header-background'
        />
        <h1 className='banner-header-text'>Poznaj nasze szkolenia</h1>
        <Image
          fluid={bannerShadow}
          className='background-rounded-shadow'
          style={{ position: "absolute" }}
          loading="eager"
        />
      </div>
      <div className='content-container'>
        <Accordion allowZeroExpanded>
          {trainings.map((node) => {
            const training = node.childTrainingsJson
            return (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <Image
                      fluid={training.banner.childImageSharp.fluid}
                      style={{ position: "absolute", width: "100%", height: "100%" }}
                    />
                    <h2>{training.title}</h2>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    {training.pList.map((child, index) => (
                      <li key={`content_item_${index}`}>
                        <Link to={`/trainings/${training.slug}/${child.slug}`} className='accordion-list-item'>
                          <div>{child.title}</div>
                          <div className='accordion-list-item__right-side'>
                            <span>{timeConverter(child.duration)}</span>
                            &nbsp;
                            <span style={{fontSize: '18px'}}>{child.price}zł</span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "trainings"}}, sort: {order: ASC, fields: name}) {
      nodes {
        childTrainingsJson {
          slug
          title
          banner {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pList {
            slug
            title
            price
            duration
          }
        }
      }
    }
    bannerHeader: file(relativePath: {eq: "trainings/images/banner_header.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
